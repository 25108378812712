import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import Cookies from 'js-cookie';

const SurveyModalPopup = (userId) => {
    const [open, setOpen] = useState(false);
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);
    const [clickedOutside, setClickedOutside] = useState(false);

    useEffect(() => {
        const doNotShowSurvey = Cookies.get('doNotShowSurvey');
        if (!doNotShowSurvey) {
            setOpen(true);
        }
    }, []);

    const handleClose = () => {
        if (doNotShowAgain) {
            Cookies.set('doNotShowSurvey', 'true', { expires: 365 });
        } else {
            Cookies.set('closedFirstTime', 'true', { expires: 365 });
        }
        setOpen(false);
    };

    const handleOutsideClick = () => {
      setClickedOutside(true);
      setTimeout(() => {
        setClickedOutside(false);
      }, 500); // flash for 0.5 seconds
    };

    if (Cookies.get('doNotShowSurvey')) return null;

    return (
        <Modal
            open={open}
            onClose={() => {
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={handleOutsideClick} // add this prop
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    zIndex: 1300
                }}
            >
                <Typography variant="h6" component="h1" sx={{ mb: 2, textAlign: 'center' }}>
                    {`Hey `}
                    <span style={{ fontWeight: '600' }}>
                      {userId.userId || ''}
                    </span>
                    {`, `}
                    <span style={{ color: '#3B82F6' }}>
                      Tailored Tutor
                    </span>
                    {` needs your feedback!`}
                </Typography>
                <Typography variant="subtitle1" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                  Schedule a 30-minute call with us and receive <br/>a guaranteed $20 Amazon Gift Card.
                </Typography>
                <img src="/amazon_giftcard.png" alt="Amazon gift card" style={{ width: '100%', maxWidth: 300, margin: '0.25rem auto', display: 'block' }} />
                <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                  limited calls available: 10 spots across all universities
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <FormControlLabel
                          control={<Checkbox checked={doNotShowAgain} onChange={() => setDoNotShowAgain(!doNotShowAgain)} />}
                          label="Do not show again"
                          sx={{ mt: 2 }}
                      />
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="inherit"
                        sx={{
                            mt: 2,
                            backgroundColor: clickedOutside ? 'black' : 'primary', // flash yellow when clicked outside
                            color: clickedOutside ? 'white' : 'black',
                        }}
                    >
                        No thanks
                    </Button>
                    <Button
                        href="https://calendar.app.google/uXaLhXozuzNG4Xki6"
                        target="_blank"
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 2,
                            ml: 2,
                            backgroundColor: clickedOutside ? 'inherit' : 'primary', // flash yellow when clicked outside
                            color: clickedOutside ? 'black' : 'white',
                        }}
                        onClick={handleClose}
                    >
                        Schedule
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
  
  export default SurveyModalPopup;


